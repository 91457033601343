<template>
  <div class="content b50" v-wechat-title="$route.meta.title">
    <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
      <!-- <van-nav-bar title="课程" :border="false" class="content-title" /> -->
      <van-tabs
        v-model="active"
        :ellipsis="false"
        title-active-color="#333333"
        color="#23B8FF"
        line-width="16px"
        class="content-item"
        @click="onTabsClick"
      >
        <van-tab :title="item.title" v-for="(item) in labelArr" :key="item.id" class="title-item" />
      </van-tabs>
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item
          v-for="(item,index) in advertisementList"
          :key="index"
          style="height: 150px;overflow: hidden;border-radius: 10px; margin: auto;"
        >
          <img
            width="100%"
            :src="`http://114.116.236.37:9001/${item.advertisementUrl}`"
            alt
            class="content-img"
          />
        </van-swipe-item>
      </van-swipe>
      <AllCourses ref="allCourses" :resVal="tabVal" />
    </van-pull-refresh>
    <!-- tabbar栏 -->
    <Footer></Footer>
  </div>
</template>

<script>
import { POST } from "@/common/request";
const api = {
  querySrCurriculum: "/xkcshowApi/show/querySrCurriculum.htm",
  querySrClassificationTree: "/xkcshowApi/show/querySrClassificationTree.htm",
  querySrAdvertisementInfoList:
    "/xkcshowApi/show/querySrAdvertisementPageList.htm"
};
import Footer from "@/components/Footer";
import AllCourses from "@/components/AllCourses.vue";
export default {
  name: "Curriculum",
  components: {
    Footer,
    AllCourses
  },
  data() {
    return {
      active: 0,
      isLoading: false,
      labelArr: [],
      tabVal: "0",
      advertisementList: []
    };
  },
  mounted() {
    this.postClassificationTree();
    this.getCurriculum();
  },
  methods: {
    getCurriculum() {
      POST(api.querySrAdvertisementInfoList, {
        advertisementStatus: 0,
        page: 1,
        size: 10
      })
        .then(res => {
          if (res.code === 200) {
            this.advertisementList = res.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    postClassificationTree() {
      POST(api.querySrClassificationTree)
        .then(res => {
          if (res.code === 200) {
            // this.gridArr =
            this.labelArr = [
              {
                id: "0",
                title: "全部"
              },
              ...res.data
            ];
          }
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        })
        .catch(error => {
          console.log(error);
        });
    },
    onTabsClick(name) {
      this.tabVal = this.labelArr[name].id;
    },
    onRefresh() {
      this.postClassificationTree();
      this.getCurriculum();
      if (this.tabVal === "0") {
        this.$refs.allCourses.getCurriculum("0", true);
      } else {
        this.tabVal = "0";
        this.active = 0;
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar__title {
  font-weight: 700;
  color: #333333;
}
.title-item {
  justify-content: space-between;
  font-size: 14px;
  color: #666666;
}
.my-swipe {
  // margin: 11px 0 12px 0;
  margin: 11px 16px;
  .van-swipe-item {
    color: #fff;
    font-size: 20px;
    text-align: center;
  }
  /deep/.van-swipe__indicator {
    width: 10px;
    height: 2px;
    border-radius: 50px;
  }
  /deep/ .van-swipe__indicators {
    position: absolute;
    left: 82%;
  }
  // .content-img {
  //   width: 100%;
  //   height: 100%;
  //   padding: 0 8px;
  // }
}
</style>